<template>
  <div class="mc-container">
    <div class="cm-header">
      <img
        style="height: 60px; width: 50px"
        src="@/assets/logo.png"
        alt="logo"
      />
      <div class="cm-text-logo">Mobilecrosspay</div>
    </div>
    <div class="cm-content">
      <div class="cm-box">
        <p class="info">
          Mobilecrosspay, facilite le paiement mobile en Afrique. Payer et
          encaisser simplement à distance en toute sécurité.
          <img class="illustration" src="@/assets/sp.svg" alt="illustration" />
        </p>
      </div>
      <div class="cm-form">
        <div style="margin: auto; width: 220px">
          <ul class="steps is-horizontal">
            <li
              :class="
                key === active ? 'steps-segment is-active' : 'steps-segment'
              "
              v-for="(item, key) in step"
              :key="key"
            >
              <span class="steps-marker"></span>
            </li>
          </ul>
        </div>
        <div>
          <div class="ig-svg">
            <img v-if="verify == '1'" src="@/assets/check.svg" alt="" />
            <p class="text-center">
              <span v-if="verify == '0'">Ne réactualiser pas la page,</span>
              <span v-else-if="verify == '1'">Verification terminé</span>
              <span v-else>Lien introuvable</span>
            </p>
            <p class="text-desc">
              <span v-if="verify == '0'"
                >Nous procédons à la vérification de votre compte. Veuillez
                patienter.</span
              >
              <span v-else-if="verify == '1'"
                >Nous avions procédé à la vérification de votre avec succès.
                Vous allez etre redirigé vers la page de connexion.</span
              >
              <span v-else>Ce lien est introuvable ou incorrect</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TYPES } from "@/store/types";
export default {
  data: () => ({
    step: 4,
    active: 3,
    loading: true,
    verify: 0,
  }),

  mounted() {
    this.accountVerification();
  },

  methods: {
    accountVerification() {
      this.$loading(true);
      this.$store
        .dispatch(`${TYPES.modules.USER}/${TYPES.actions.USER_VERIFICATION}`, {
          token: this.$route.params.token,
        })
        .then((res) => {
          this.$loading(false);
          if (res == true) {
            this.verify = "1";
            setTimeout(() => {
              this.$router.push({ name: "login" });
            }, 5000);
          } else this.verify = "2";
        })
        .catch((error) => {
          this.$loading(false);
          this.verify = "2";
          setTimeout(() => {
            //this.$router.push({ name: "login" });
          }, 5000);
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.mc-container {
  background-color: #ecf7ff;
  width: 100%;
  height: 100vh;
  .cm-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    height: 70px;
    width: 100%;
    img {
      height: 50px;
    }
    .cm-text-logo {
      margin-left: 10px;
      font-size: 20px;
      font-weight: bold;
    }
  }
}
.cm-content {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: row;
  grid-gap: 20px;
  margin-left: 10%;
  margin-right: 10%;
}
.info {
  font-weight: bold;
  font-size: 22px;
}
.cm-form {
  background-color: #fff;
  min-height: 400px;
  max-height: 600px;
  border-radius: 5px;
  padding: 50px;
  .cm-form-title {
    text-align: center;
    color: #222f5a;
    font-weight: bold;
    font-size: 22px;
    line-height: 21px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.cm-info-bottom {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 1024px) {
  .illustration {
    display: none !important;
  }
  .info {
    font-weight: bold;
    font-size: 22px;
    text-align: center !important;
  }
  .cm-content {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    margin-right: 10%;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 636px) {
  .form-add {
    grid-template-columns: 1fr !important;
    grid-gap: 10px !important;
  }

  .password_section {
    grid-template-columns: 1fr !important;
    grid-gap: 10px;
  }
}
.form-add {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}
.password_section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}
.ig-svg {
  img {
    height: 80px;
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  align-content: center;
  justify-content: center;
  padding: 30px;
}
.text-center {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}
.text-desc {
  margin-top: 30px;
  text-align: center;
}
</style>